button {
    display: inline-flex;
    align-items: center;
    padding: 8px;
    border: none;
    background-color: #f5f5f5;
    color: #444;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .icon {
    margin-right: 8px;
  }
  
  .label {
    margin-left: 8px;
  }
  