.tiptap-toolbar button {
  margin: 1px;
  width: 100%;
  height: 100%;
  aspect-ratio: calc(1 / 1);
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  border: 1px solid #fff;
  background-color: #4b5563;
  display: flex;
  justify-content: center;
  text-transform: capitalize;
}

.is-active {
  background-color: #fff !important;
  color: black !important;
}
.ProseMirror {
  min-height: 500px;
  height: 100%;
  overflow: scroll;
}
