

.ProseMirror {
  min-height: 500px;
  height: 100%;
  overflow: scroll;
  color:black;
}

.ProseMirror bold{
  color:black;
}

.tiptap-toolbar button {
  width: 80px;
  height: 50px;
  margin-left: 10px;
  margin-right: 10px;
  border: none;
  outline: none;
  color: #fff;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.tiptap-toolbar button:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

/* .tiptap-toolbar button:active {
  background-color: #fff !important;
  color: #000
} */
.is-active {
  background-color: #fff !important;
  color: #000
}

.tiptap-toolbar button:active:after {
  background: transparent;
}

.tiptap-toolbar button:hover:before {
  opacity: 1;
}

.tiptap-toolbar button:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: #111; */
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% { background-position: 0 0; }
  50% { background-position: 400% 0; }
  100% { background-position: 0 0; }
}