.password-strength-meter {
    text-align: left;
  }
  
  .password-strength-meter-progress {
    -webkit-appearance: none;
    appearance: none;
    width: 49%;
    height: 8px;
  }
  
  .password-strength-meter-progress::-webkit-progress-bar {
    background-color: #eeeeee56;
    opacity: 100%;
    border-radius: 3px;
  }
  
  .password-strength-meter-label {
    color : white;
    font-size: 14px;
  }

    
  .strength-Weak {
    color: #c10c09;
  }

  .strength-Fair {
    color: #d36217;
  }

  .strength-Good {
    color: #e2e615;
  }

  .strength-Strong {
    color: #a4f062;
  }

  .strength-Very_Strong {
    color: #09b61d;
  }
  
  .password-strength-meter-progress::-webkit-progress-value {
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }
  
  .strength-Weak::-webkit-progress-value {
    background-color: #c10c09;
  }
  .strength-Fair::-webkit-progress-value {
    background-color: #d36217;
  }
  .strength-Good::-webkit-progress-value {
    background-color: #e2e615;
  }
  .strength-Strong::-webkit-progress-value {
    background-color: #a4f062;
  }
  .strength-Very_Strong::-webkit-progress-value {
    background-color: #09b61d;
  }  