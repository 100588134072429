.navbar-font {
  font-family: "league spartan";
  /* font-size: 25px; */
}
.nav-item {
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
}
.navbar-main {
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}
