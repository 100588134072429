/* App.css */

.container {
  margin: 0 auto;
  max-width: 1150px;
  padding: 20px;
}
.app-container {
  margin: 0 auto;
  max-width: 1150px;
  padding: 20px;
}

/* Comment.css */
/* .comment-container {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
    margin: 10px 0;
    padding: 10px;
  } */
.comment-container {
  background-color: #171616c2;
  border: 1px solid #1a1a1b;
  border-radius: 40px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  padding-right: 10px;
  padding-left: 10px;
}
.comment-header {
  display: flex;
  justify-content: space-between;
  padding: 3px;
  /* margin-bottom: 10px; */
}
.comment-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 20px;
  margin-top: 10px;
  padding: 2px;
}
.comment-score {
  color: white;
}
.comment-author {
  color: #c6c8ca;
  font-weight: bold;
  text-decoration: none;
  text-align: left;
  margin-top: 15px;
  margin-left: 10px;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 10px; */
}

.comment-upvote {
  color: #7f8182;
  cursor: pointer;

  /* margin-right: 10px; */
}

.comment-upvote:hover {
  color: #0079d3;
}

.comment-downvote {
  color: #7f8182;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 40px;
}

.comment-downvote:hover {
  color: #0079d3;
}

.comment-message {
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 10px;
  color: #b2b4b6;
}

.comment-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
/* remove the box around the delete button */

.comment-delete-btn {
  /* color: #555; */
  cursor: pointer;
  border: none;
  /* background-color: #fff ; */
  color: #7f8182;
  background-color: #1a1a1b;
  font-weight: bolder;
  margin-left: 40px;
}

.reply-message-input {
  border: 2px solid #313233;
  background-color: #1a1a1b;
  border-radius: 40px;
  /* box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); */
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  /* margin-left:35px; */
  padding: 10px;
  width: 100%;
  color: aliceblue;
}
.comment-form-message {
  border: 2px solid #313233;
  background-color: #1a1a1b;
  border-radius: 4px;
  /* box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1); */
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 35px;
  padding: 10px;
  width: 100%;
  color: aliceblue;
}

.comment-delete-btn:hover {
  /* color: #d9d9d9; */
}
/* make the reply button round and don;t give a box around it */
.comment-reply-btn {
  color: #7f8182;
  cursor: pointer;
  text-decoration: none;
  margin-left: 40px;
  border-radius: 50%;
  border: none;
  background-color: #1a1a1b;
  font-weight: bold;
}
/* .comment-reply-btn {
    color: #0079d3;
    cursor: pointer;
    text-decoration: none;
    margin-left: 20px;
  } */

.comment-reply-btn:hover {
  text-decoration: underline;
}

/* CommentForm.css */
.comment-form {
  margin-bottom: 20px;
}

.comment-form h3 {
  margin: 0;
}

.comment-form textarea {
  border: 2px solid #313233;
  background-color: #1a1a1b;
  border-radius: 10px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  margin-left: 35px;
  padding: 10px;
  width: 100%;
}

.comment-form textarea {
  height: 100px;
}

.comment-form button[type="submit"] {
  background-color: white;
  border: none;
  border-radius: 4px;
  color: black;
  cursor: pointer;
  font-size: 14px;
  padding: 10px;
  margin-left: 35px;
}

.comment-form button[type="submit"]:hover {
  background-color: #005fad;
}

/* CommentList.css */
.comment-list {
  margin: 20px 0;
}

.comment-container {
  margin-bottom: 20px;
}

.reply-container {
  margin-left: 50px;
}

.reply-form-container {
  margin-left: 50px;
}

/* ReplyForm.css */
.reply-form {
  margin-bottom: 20px;
}

.reply-form input[type="text"],
.reply-form textarea {
  border: 2px solid #313233;
  background-color: #1a1a1b;
  border-radius: 10px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  padding: 10px;
  padding-right: 20px;
  width: 100%;
}

.reply-form textarea {
  height: 100px;
}

.reply-form button[type="submit"] {
  background-color: white;
  border: none;
  border-radius: 4px;
  color: black;
  cursor: pointer;
  font-size: 14px;
  padding: 10px;
}
.reply-form button[type="submit"]:hover {
  background-color: #005fad;
}

/* Add the following styles to your CSS file */
.comment-list {
  margin: 0 auto;
  padding: 0 20px;
}

.comment-message {
  border-left: 2px solid#7f8182;
  margin-left: 20px;
  padding-left: 20px;
  position: relative;
}

/* .comment-container:before {
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    height: 100%;
    width: 2px;
    background-color: #ccc;
  } */

.reply-container {
  border-left: 2px solid #7f8182;
  margin-left: 40px;
  padding-left: 20px;
  position: relative;
}

/* .reply-container:before {
    content: '';
    position: absolute;
    top: 0;
    left: -30px;
    height: 100%;
    width: 2px;
    background-color: #ccc;
  } */
